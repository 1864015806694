/* eslint-disable dot-notation */
import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../../components/theme/Layout'
import Footer from '../../../components/theme/Footer'
import BlogPage from '../../../components/pages/BlogPage'

const HomeSolutionsBlog = ({ data }) => {
  const urls = {};
  let hideSignUp = false
  if(data.prismic.home_solution_article) {
    if(data.prismic.home_solution_article.category.name[0].text === 'Soins des animaux'){
      hideSignUp = true
    }
  }

  if (data.prismic.home_solution_article) {
    data.prismic.home_solution_article._meta.alternateLanguages.forEach((language) => {
      if (language.lang === 'en-ca') {
        urls['en'] = `/home-solutions/${language.uid}`;
      } else if (language.lang === 'fr-ca') {
        urls['fr'] = `/fr/solutions-maison/${language.uid}`;
      } else if (language.lang === 'zh-tw') {
        urls['zh_hant'] = `/zh-hant/home-solutions/${language.uid}`;
      } else if (language.lang === 'zh-cn') {
        urls['zh_hans'] = `/zh-hans/home-solutions/${language.uid}`;
      }
    });
  }

  return (
    <Layout
      meta={{}}
      urls={urls}
      locale='fr'
    >
      {data.prismic.home_solution_article ?

        (
          <BlogPage
            locale='fr'
            switchPath={`/home-solutions/${data.prismic.home_solution_article._meta.alternateLanguages[0].uid}`}
            data={data.prismic.home_solution_article}
          />
        ) : null}
      <Footer hideSignUp={hideSignUp} />
    </Layout>
  )
}

export const query = graphql`
query($uid: String!) {
  prismic {
    home_solution_article(lang: "fr-ca", uid: $uid) {
      title
      _meta {
        uid
        lang
        alternateLanguages {
          uid
          lang
        }
      }
      category {
        ... on PRISMIC_Home_solution_category {
          name
          _linkType
        }
      }
      body {
        ... on PRISMIC_Home_solution_articleBodyText {
          type
          primary {
            text
          }
        }
        ... on PRISMIC_Home_solution_articleBodyVideo {
          type
          label
          primary {
            video
          }
        }
        ... on PRISMIC_Home_solution_articleBodyBullet_list {
          label
          primary {
            title1
          }
          fields {
            item
          }
          type
        }
        ... on PRISMIC_Home_solution_articleBodyNumber_list {
          type
          label
          primary {
            title1
          }
          fields {
            item
          }
        }
        ... on PRISMIC_Home_solution_articleBodySide_by_side_image {
          type
          label
          primary {
            left_image
            right_image
          }
        }
      }
      coupon {
        ... on PRISMIC_Home_solution_coupon {
          title
          sub_title
          description
          button_name
          coupon_event_name
          coupon_link {
            ... on PRISMIC__ExternalLink {
              url
            }
          }
          image
        }
      }
      tip {
        ... on PRISMIC_Home_solution_tip {
          title
          icon
          tip_link {
            ... on PRISMIC__ExternalLink {
              url
            }
            ... on PRISMIC_Home_solution_article {
              _meta {
                uid
              }
            }
            _linkType
          }
          tip_image
          tip_background
          description
          button_name
          tip_style
        }
      }
    }
  }
}
`

HomeSolutionsBlog.query = query;

export default HomeSolutionsBlog
